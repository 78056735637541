body, html {
  background-color: #007575 !important;
}

/* * {
  outline: 1px solid green;
} */

.App {
  text-align: center;
  max-width: 900px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--bs-dark) !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

a {
  text-decoration: none !important;
}

/* FontAwesome Icons */
#iconContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  border: 0px solid var(--bs-light);
  padding: 2rem;
  text-align: center;
}

svg {
  color:bisque;
}
/* End FontAwesome Icons */

#hotlineList > li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.list-group-item > a {
  text-decoration: none !important;
}

@media screen and (max-width: 600px) {
  #iconContainer > div > svg {
    height: 2.2 !important;
  }
  #navbarTitle {
    display: none;
  }
}

